<template>  
<div class="themev2">
  <br>  
  <div class="logo-centered"></div>

  <div class="w560" v-if="!form.token || !form.email">

    <div class="w300" v-if="!resetRequested">
      <h1>Reset password</h1>
      <p>Enter the email you registered your account with and we will send you a link which will let you create a new password</p> 
    </div>

    <ValidationObserver v-slot="{ invalid, handleSubmit}" tag="div" class="w300" v-if="!resetRequested">
      <form ref="requestForm" method="POST" @submit.prevent="handleSubmit(onSubmitRequest)" spellcheck="false" :class="{disabled: spinner}">        

        <ValidationProvider name="email" rules="required" tag="div" class="inputrow" v-slot="{ classes, errors }">
          <label for="email" class="emaillabel">EMAIL YOU REGISTERED WITH</label>
          <input v-model="form.email" name="email" type="email" novalidate :class="classes" :disabled="spinner">          
          <span class="errormessage">{{ errors[0] }}</span>
        </ValidationProvider>

        <br>        
        <button class="full" type="submit" :class="{disabled: spinner}" :disabled="spinner">Reset my password</button>

      </form>
    </ValidationObserver> 

    <div class="w300" v-if="resetRequested">
      <h1><span class="checkmark"></span>Check your email</h1>
      <p>We've emailed you a link to <b>{{form.email}}</b> that you can use to reset your password and create a new one.</p>
      <button class="full" @click="$router.push('/login')">Back to login screen</button>
    </div>

    <span class="spinner" v-if="spinner" />

    <div class="formerror" v-if="error">{{error}}</div>

  </div>

  <div class="w560" v-if="form.token && form.email">

    <div class="w300">
      <h1>Choose new password</h1>
      <p>Password must be minimum 8 characters and contain at least 1 number.</p> 
    </div>

    <ValidationObserver v-slot="{ invalid, handleSubmit}" tag="div" class="w300">
      <form ref="resetForm" method="POST" @submit.prevent="handleSubmit(onSubmitReset)" spellcheck="false" :class="{disabled: $store.getters.loading}">        

        <ValidationProvider mode="eager" name="password" ref="password" rules="required|password" tag="div" class="inputrow" v-slot="{ classes, errors }">
          <label for="password">New password</label>
          <input v-model="form.password" name="password" type="password" novalidate :class="classes" placeholder="" :disabled="$store.getters.loading" />          
          <span class="errormessage">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider mode="eager" name="password2" rules="required|confirmed:password" tag="div" class="inputrow" v-slot="{ classes, errors }">
          <label for="password2">Repeat new password</label>
          <input v-model="form.password2" name="password2" type="password" novalidate :class="classes" placeholder="" :disabled="$store.getters.loading" />          
          <span class="errormessage">{{ errors[0] }}</span>
        </ValidationProvider>

        <br>        
        <button class="insead large" type="submit" :class="{disabled: spinner}" :disabled="spinner">Set password</button>

        <span class="spinner" v-if="spinner" />

        <div class="formerror" v-if="$store.state.auth.status == 'pwd_reset_error'">{{$store.state.auth.error}}</div>        

      </form>
    </ValidationObserver>
  </div>

</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import '@/veevalidate.js'
import axios from 'axios'
import {PASSWORD_RESET} from "../store/actions/auth"

export default {
  name: 'PasswordReset',
  data: function(){
    return {
      error: '',
      form: {
        email: '',
        password: '',
        password2: '',
        token: ''        
      },
      resetRequested: false,
      loading: false
    }
  },
  computed: {   
    spinner: function() { return this.loading || this.$store.getters.loading; }
  },
  methods: {  
    async onSubmitRequest() {    
      try{ 
        this.loading = true;
        this.error = undefined
        await axios({ url: "auth/requestpasswordreset?email=" + encodeURIComponent(this.form.email), method: "POST" }); 
        this.resetRequested = true;       
      }
      catch(err){
        this.error = err.response ? err.response.data : "Something went wrong, please try again or contact us.";
      }
      finally{
        this.loading = false;        
      }
    },  
    async onSubmitReset() {    
      await this.$store.dispatch(PASSWORD_RESET, this.form)
    },  
    tryParseResetToken() {    
      if(this.$route.query.token && this.$route.query.email){
        this.form.email = this.$route.query.email;
        this.form.token = this.$route.query.token;
      }
    } 
  },
  components: {
    ValidationProvider, 
    ValidationObserver
  },
  mounted: function() {
    this.tryParseResetToken();
  }
}
</script>

<style lang="scss">

</style>